// eslint-disable-next-line no-redeclare,no-unused-vars
function hideElements(param) {
  var view = param.view;
  var object = param.object;
  var key = param.key;
  var line = param.line;
  var settings = getFieldSettings(param, key);
  var row = param.row;
  if (!row) row = getData(object, param.id);
  var value = param.value;
  if (!value) value = row[key];
  var selfTriggered = param.selfTriggered;
  var clear = {};
  var action = [];

  // rebuild other fields
  if (settings.rebuild && selfTriggered)
    $.each(settings.rebuild, function (i, key) {
      view.lines[key].rebuild();
    });

  // disable other elements
  if (settings.disable) {
    if (!view.disable) view.disable = {};
    if (!view.disableTip) view.disableTip = {};
    $.each(settings.disable, function (val, keys) {
      if (!keys) return;
      if (!isArray(keys)) keys = [keys];
      val = convertWhereValue(key, val, {}, {object: object});
      $.each(keys, function (i, k) {
        if (!k) return;
        var line2 = view.body.find(
          '.form-group[key=' + k + ']:not(.form-group-table)'
        );
        var success = checkWhereValue(object, row, key, val, true);
        if (success && !line2.hasClass('disabled')) {
          var el = line2
            .addClass('disabled')
            .find('input:not(.select-input),button,select')
            .attr('disabled', true);
          if (getObjectValue(fields, [object, k, 'edit']))
            el.find('.read').off('contextmenu');
          if (!view.disable[k]) {
            view.disable[k] = key;
            view.disableTip[k] =
              'Schreibschutz (' +
              settings.label +
              ' = ' +
              convert({
                value: value,
                settings: settings,
                entity: true,
              }) +
              ')';
            if (
              selfTriggered &&
              el[0] &&
              ((isArray(row[k]) && row[k].length) ||
                (!isArray(row[k]) && row[k])) &&
              !fields[object][k].default &&
              !fields[object][k].sum
            )
              clear[k] = '';
          }
          line2.addTooltip(view.disableTip[k]);
          // if (el.is('select')) el.prev().addClass('pointer-nodrop').addTooltip(view.disableTip[k]);
          // else
          // 	el.wrap(function () {
          // 		return $('<span class="pointer-nodrop d-inline-block"></span>').addTooltip(view.disableTip[k]);
          // 	});
        } else if (
          !success &&
          line2.hasClass('disabled') &&
          view.disable[k] == key
        ) {
          delete view.disable[k];
          line2
            .removeClass('disabled')
            .find('input:not(.select-input),button,select')
            .attr('disabled', false);
          if (isString(clear[k])) delete clear[k];
        }
      });
    });
  }

  // disabled by other elements
  if (settings.disabled) {
    if (!view.disabled) view.disabled = {};
    var line2 = view.body.find(
      '.form-group[key=' + key + ']:not(.form-group-table)'
    );
    var pm = {object: object, nullAsEmpty: true};
    var success = checkWhere(row, settings.disabled, pm);
    if (
      settings.disabled2 &&
      !success &&
      checkWhere(row, settings.disabled2, pm)
    )
      success = true;
    if (success)
      line2
        .addClass('disabled')
        .find('input:not(.select-input),button,select')
        .attr('disabled', true);
    else
      line2
        .removeClass('disabled')
        .find('input:not(.select-input),button,select')
        .attr('disabled', false);
    if (pm.keys)
      $.each(pm.keys, function (i, k) {
        if (!view.disabled[k]) view.disabled[k] = [];
        if (k != key && !inArray(key, view.disabled[k]))
          view.disabled[k].push(key);
      });
  }

  // trigger disabled elements
  if (view.disabled && view.disabled[key])
    $.each(view.disabled[key], function (i, k) {
      hideElements(Object.assign({}, param, {key: k}));
    });

  // elements
  // if (settings.hide && (!view.hidden || !inArray(key, view.hidden))) {
  if (settings.hide) {
    if (!view.hidden) view.hidden = [];
    if (test && !view.hiddenBy) view.hiddenBy = {};
    if (!settings.hiddenFields) settings.hiddenFields = [];
    $.each(settings.hiddenFields, function (i, k) {
      if (!k) return;
      action.push(function () {
        view.body
          .find('.form-group[key=' + k + ']:not(.form-group-table)')
          .removeClass('d-none');
        if (k == 'disabled')
          view.buttonsRight.children('.btn.trash').removeClass('d-none');
      });
      // view.hidden.splice(strpos(view.hidden, k), 1);
      if (inArray(k, view.hidden)) view.hidden = arrayRemove(view.hidden, k);
      if (isString(clear[k])) delete clear[k];
    });
    var hide = [];
    $.each(settings.hide, function (val, keys) {
      val = convertWhereValue(
        key,
        val,
        {},
        {object: object, nullAsEmpty: true}
      );
      var success = checkWhereValue(object, row, key, val, true);
      if (!isArray(keys)) keys = keys.split(',');
      if (success) hide = arrayMerge(hide, keys);
      else
        $.each(keys, function (i, k) {
          if (str_contains(k, ':')) [k] = k.split(':');
          if (inArray(k, view.hidden)) {
            action.push(function () {
              view.body
                .find('.form-group[key=' + k + ']:not(.form-group-table)')
                .removeClass('d-none');
              if (k == 'disabled')
                view.buttonsRight.children('.btn.trash').removeClass('d-none');
            });
            view.hidden = arrayRemove(view.hidden, k);
          }
        });
    });
    $.each(hide, function (i, k) {
      if (!k) return;
      action.push(function () {
        if (str_contains(k, ':')) {
          let block;
          [k, block] = k.split(':');
          var el = view.body
            .find(
              '.card[ident=' +
                block +
                '] .form-group[key="' +
                k +
                '"]:not(.form-group-table)'
            )
            .addClass('d-none');
          if (k == 'disabled')
            view.buttonsRight.children('.btn.trash').addClass('d-none');
        } else {
          el = view.body
            .find('.form-group[key="' + k + '"]:not(.form-group-table)')
            .addClass('d-none');
          if (k == 'disabled')
            view.buttonsRight.children('.btn.trash').addClass('d-none');
        }
        view.hidden.push(k);
        if (test) view.hiddenBy[k] = key;
        if (!inArray(k, settings.hiddenFields)) settings.hiddenFields.push(k);
        if (
          selfTriggered &&
          el[0] &&
          ((isArray(row[k]) && row[k].length) ||
            (!isArray(row[k]) && row[k])) &&
          !fields[object][k].default &&
          !fields[object][k].sum
        )
          clear[k] = '';
      });
    });
    // if (test) {
    // 	var info = cont.find('.lineInfo');
    // 	if (!info[0]) info = $('<span class="adminInfo"><i class="lineInfo far fa-eye-slash"></i></span>').appendTo(cont);
    // 	info.addTooltip('hidden: ' + settings.hiddenFields.join(', '));
    // }
  }

  // hidden check
  if (view.hiddenCheck && view.hiddenCheck[key]) {
    $.each(view.hiddenCheck[key], function (i, key2) {
      var line2 = view.body.find(
        '.form-group[key=' + key2 + ']:not(.form-group-table)'
      );
      if (isHidden(fields[object][key2], row, {nullAsEmpty: true}))
        line2.addClass('d-none');
      else line2.removeClass('d-none');
    });
  }
  if (settings.emptyHidden && isArray(settings.emptyHidden)) {
    $.each(settings.emptyHidden, function (i, fieldIndex) {
      if (!line.els || !line.els[fieldIndex]) return;
      if (value) line.els[fieldIndex].removeClass('d-none');
      else line.els[fieldIndex].addClass('d-none');
    });
  } else if (settings.emptyHidden && settings.full) {
    if (isEmpty(value)) line.parent().parent('.row').addClass('d-none');
    else line.parent().parent('.row').removeClass('d-none');
  } else if (settings.emptyHidden) {
    if (isEmpty(value)) line.addClass('d-none');
    else line.removeClass('d-none');
  } else if (settings.zeroHidden) {
    if (!value) line.addClass('d-none');
    else line.removeClass('d-none');
  }
  if (settings.filledHidden && isArray(settings.filledHidden) && line.els)
    $.each(settings.filledHidden, function (i, fieldIndex) {
      fieldIndex = parseInt(fieldIndex);
      if (!line.els[fieldIndex]) return;
      if (isEmpty(value)) line.els[fieldIndex].removeClass('d-none');
      else line.els[fieldIndex].addClass('d-none');
    });

  // blocks
  if (settings.hideBlock && param.id) {
    if (!view.hiddenBlocks) view.hiddenBlocks = {};
    if (view.hiddenBlocks[key] && view.hiddenBlocks[key].length)
      $.each(view.hiddenBlocks[key], function (i, card) {
        if (!card) return;
        action.push(function () {
          view.showCard(card);
        });
      });
    view.hiddenBlocks[key] = [];
    var blocksAll;
    $.each(settings.hideBlock, function (val, blocks) {
      val = convertWhereValue(key, val, {}, {object: object});
      var success = checkWhereValue(object, row, key, val, true);
      if (!success) return;

      if (!isArray(blocks)) blocks = blocks.split(',');
      if (blocks[0] == 'next')
        blocksAll = view.body
          .find('.form-group[key=' + key + ']')
          .parents('.card:first')
          .nextAll('.card')
          .map(function () {
            return $(this).attr('ident');
          });
      else if (blocksAll) blocksAll = arrayMerge(blocksAll, blocks);
      // else if (blocksAll && val.not) blocksAll = arrayMerge(blocksAll, blocks);
      // else if (blocksAll) blocksAll = arrayOverlapping(blocksAll, blocks);
      else blocksAll = blocks;
    });
    if (blocksAll)
      $.each(blocksAll, function (i, card) {
        if (!card) return;
        action.push(function () {
          view.hideCard(card);
        });
        view.hiddenBlocks[key].push(card);
      });
  }
  if (settings.collapseBlock) {
    $.each(settings.collapseBlock, function (val, blocks) {
      val = convertWhereValue(key, val, {}, {object: object});
      if (checkWhereValue(object, row, key, val, true))
        $.each(blocks, function (i, block) {
          view.body
            .find('.card[ident=' + block + '] .card-titlebar')
            .trigger(click);
        });
    });
  }

  if (action.length)
    $.each(action, function (i, func) {
      if (view.formBuilt) func();
      else view.afterBuilt.push(func);
    });
}
